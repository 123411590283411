import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Banner from '../containers/banner';
import KeyFeatures from '../containers/keyfeatures';
import AppSlider from '../containers/appslider';
import AboutVideo from '../containers/aboutvideo';
import Dashboard from '../containers/dashboard';
import Products from '../containers/products';
import FeatureComparison from '../containers/featurecomparison';
import Faq from '../containers/faq';
import ResellerList from '../containers/resellerlist';


const IndexPage = () => (
  <Layout page="startpage">
    <Seo title="Nearkey" />
    <Banner />
    <KeyFeatures />
    <AppSlider />
    <AboutVideo />
    <Dashboard />
    <Products />
    <FeatureComparison />
    <Faq />
    <ResellerList />
  </Layout>
)

export default IndexPage
