import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Container from "../components/container"

import "./dashboard.css"

const Dashboard = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "dashboard"}) {
                subBodyTitle
            }    
            contentfulDashboard(contentfulid: {eq: 1}) {
                title
                subtitle
                description
                image {
                  file {
                    url
                  }
                }
            }
          }
        `
    )

    return (
        <Container id="dashboard" className="anchor">
            <div className="dashboardWrapper">
                <div className="anotherCustomWrapper">
                    <div className="dashboardBlock">
                        <div className="dashboardTextWrapper">
                            <h1>{data.contentfulDashboard.title}</h1>
                            <h2>{data.contentfulDashboard.subtitle}</h2>
                            <p>{data.contentfulDashboard.description}</p>
                            <Link to="#reseller">
                                <button className="dashboardButton">{data.contentfulSection.subBodyTitle}</button>
                            </Link>
                        </div>
                        <div className="dashboardImageWrapper">
                            <img
                                src={data.contentfulDashboard.image.file.url}
                                alt={data.contentfulDashboard.title}
                                className="dashboardImage"
                            >
                            </img>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Dashboard