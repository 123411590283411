import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { Icon } from 'react-icons-kit';
import { playCircle } from 'react-icons-kit/fa/playCircle'

import Container from "../components/container"

import "./banner.css"

const Banner = () => {

    const data = useStaticQuery(
        graphql`
          query {
            banner: contentfulAsset(title: {eq: "Banner Image"}) {
                file {
                  url
                }
                title
            }
            googleplay: contentfulAsset(title: {eq: "Google Play Badge"}) {
                file {
                  url
                }
                title
              }
            appstore: contentfulAsset(title: {eq: "App Store Badge"}) {
                file {
                  url
                }
                title
            }
            auFlag: contentfulAsset(title: {eq: "Australian Flag"}) {
                file {
                  url
                }
                title
            }
            esFlag: contentfulAsset(title: {eq: "Spanish Flag"}) {
                file {
                  url
                }
                title
            }
            header: contentfulBanner(type: {eq: "Header"}) {
                data
            }
            mainTag: contentfulBanner(type: {eq: "Main Tag Line"}) {
                data
            }
            play: contentfulAsset(title: {eq: "Play"}) {
                file {
                  url
                }
                title
            }
            airbnb: contentfulAsset(title: {eq: "airbnb logo"}) {
                file {
                  url
                }
                title
            }
            stayz: contentfulAsset(title: {eq: "stayz logo"}) {
                file {
                  url
                }
                title
            }
            vrbo: contentfulAsset(title: {eq: "vrbo logo"}) {
                file {
                  url
                }
                title
            }
            flipkey: contentfulAsset(title: {eq: "flipkey logo"}) {
                file {
                  url
                }
                title
            }
          }
        `
    )

    return (
        <Container id="home" className="">
            <div className="bannerWrapper">
                <div className="contentWrapper">
                    <div className="bannerText">
                        <div className="designedIn">
                            <div>Designed in Australia
                                <img
                                    src={data.auFlag.file.url}
                                    alt={data.auFlag.title}
                                    width="auto"
                                    height="18"
                                >
                                </img>
                            </div>
                            <div> and Spain
                                <img
                                    src={data.esFlag.file.url}
                                    alt={data.esFlag.title}
                                    width="auto"
                                    height="18"
                                >
                                </img>
                            </div>
                        </div>
                        <h1>{data.header.data}</h1>
                        <p>{data.mainTag.data}</p>
                        <div className="productLinks">
                            <Link to="#products">
                                <button className="viewProductsBtn">View Products</button>
                            </Link>
                            <Link to="/howtovideos">
                                <div className="watchVideoLink">
                                    <Icon icon={playCircle} size="25" className="playIcon" />
                                    Watch Videos
                                </div>
                            </Link>
                        </div>
                        <div className="appLinks">
                            <a href="https://play.google.com/store/apps/details?id=com.virkey.basic.cordova" target="_blank" rel="noreferrer">
                                <img
                                    src={data.googleplay.file.url}
                                    alt={data.googleplay.title}
                                >
                                </img>
                            </a>
                            <a href="https://apps.apple.com/us/app/nearkey/id1315035954" target="_blank" rel="noreferrer">
                                <img
                                    src={data.appstore.file.url}
                                    alt={data.appstore.title}
                                >
                                </img>
                            </a>
                        </div>
                    </div>
                    <div className="bannerImage">
                        <img
                            src={data.banner.file.url}
                            alt={data.banner.title}
                        >
                        </img>
                    </div>
                </div>
                <div className="customWrapper">
                    <div className="bannerFooter">
                        <p>A perfect solution for:</p>
                        <img
                            src={data.airbnb.file.url}
                            alt={data.airbnb.title}
                        >
                        </img>
                        <img
                            src={data.stayz.file.url}
                            alt={data.stayz.title}
                        >
                        </img>
                        <img
                            src={data.vrbo.file.url}
                            alt={data.vrbo.title}
                        >
                        </img>
                        <img
                            src={data.flipkey.file.url}
                            alt={data.flipkey.title}
                        >
                        </img>
                    </div>
                </div>
                <StaticImage
                    src="../images/curved_bottom.svg"
                    alt=""
                    className="curvedBottom"
                />
            </div>
        </Container >
    )
}

export default Banner