import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Container from "../components/container"

import "./faq.css"
//import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "faq"}) {
                title
                subtitle
            }   
            allContentfulFaq(sort: {fields: order, order: ASC}) {
                nodes {
                  id
                  question
                  answer {
                    raw
                  }
                }
            }
          }
        `
    )

    const Text = ({ children }) => <p>{children}</p>

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
    }

    return (
        <Container id="faq" className="anchor">
            <div className="customWrapper">
                <div className="faqWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <div className="faqBlock">
                        <Accordion allowZeroExpanded>
                            {data.allContentfulFaq.nodes.map(node => {
                                return (
                                    <div className="faqItem" key={node.id}>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {node.question}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                {renderRichText(node.answer, options)}
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </div>
                                )
                            })}
                        </Accordion>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Faq