import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "../components/container"

import "./resellerlist.css"

const ResellerList = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "reseller"}) {
                title
                subtitle
                image {
                title
                  file {
                    url
                  }
                }
                bodyText {
                  raw
                }
                subBodyTitle
            }
            allContentfulResellerList (sort: {fields: country, order: ASC}) {
                nodes {
                  id
                  country
                  url
                  companyName
                  image {
                    title
                    file {
                      url
                    }
                  }
                }
            }
          }
        `
    )

    const Text = ({ children }) => <p>{children}</p>

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
    }


    return (
        <Container id="reseller" className="anchor">
            <div className="customWrapper">
                <div className="resellerWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    {renderRichText(data.contentfulSection.bodyText, options)}
                    <img
                        src={data.contentfulSection.image.file.url}
                        alt={data.contentfulSection.image.title}
                        className="resellerImage"
                    >
                    </img>
                    <div className="resellerBlock">
                        <div className="resellerBlockTitle">{data.contentfulSection.subBodyTitle}:</div>
                        {data.allContentfulResellerList.nodes.map(node => {
                            return (
                                <div className="resellerItem" key={node.id}>
                                    <div className="resellerFlag" >
                                        <img
                                            src={node.image.file.url}
                                            alt={node.image.title}
                                        >
                                        </img>
                                    </div>
                                    <div className="resellerTitle" >
                                        <a href={node.url} target="_blank" rel="noreferrer">{node.companyName}</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ResellerList