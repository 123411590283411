import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Modal from 'react-modal';

import { Icon } from 'react-icons-kit';
import { cross } from 'react-icons-kit/icomoon/cross'

import Video from "../components/video"
import Container from "../components/container"

import "./products.css"

const Products = () => {

  const data = useStaticQuery(
    graphql`
          query {
            allContentfulProducts(sort: {fields: contentfulid, order: ASC} filter: {}) {
                nodes {
                  id
                  sku
                  productName
                  subtitle
                  title
                  description
                  image {
                    file {
                      url
                    }
                  }
                  datasheet {
                    file {
                      url
                    }
                  }
                  manual {
                    file {
                      url
                    }
                  }
                  videoUrl
                }
            }
          }
        `
  )

  const [modalIsOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);


  function openModal(videoUrl) {
    setVideoUrl(videoUrl);
    setIsOpen(true);
  }

  function closeModal() {
    setVideoUrl(null);
    setIsOpen(false);
  }

  return (
    <Container id="products" className="anchor">
      {data.allContentfulProducts.nodes.map(node => {
        return (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal"
            overlayClassName="overlay"
            ariaHideApp={false}
            key={node.id}
          >
            <Icon icon={cross} className="modalCloseBtn" onClick={closeModal} size="25" />
            <Video
              videoSrcURL={`https://www.youtube.com/embed/${videoUrl}`}
              videoTitle={node.sku}
            />
          </Modal>
        )
      })}
      <div className="customWrapper">
        <div className="productWrapper">
          {data.allContentfulProducts.nodes.map(node => {
            return (
              <div className="productBlock" key={node.id}>
                <div className="productTextWrapper">
                  <h1>{node.title}</h1>
                  <h2>{node.subtitle}</h2>
                  <p>{node.description}</p>
                  <div className="productButtonWrapper">
                    <a href={node.datasheet.file.url} target="_blank" rel="noreferrer">
                      <button className="productButton">Datasheet</button>
                    </a>
                    <a href={node.manual.file.url} target="_blank" rel="noreferrer">
                      <button className="productButton">Manual</button>
                    </a>
                    {node.videoUrl && (
                    <a href="#" onClick={() => openModal(node.videoUrl)} key={node.id}>
                      <button className="productButton">Demo Video</button>
                    </a>
                    )}
                  </div>
                </div>
                <div className="productImageWrapper">
                  <img
                    src={node.image.file.url}
                    alt={node.title}
                    className="productImage"
                  >
                  </img>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default Products