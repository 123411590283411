import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../components/container"

import "./keyfeatures.css"

const KeyFeatures = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "features"}) {
                title
                subtitle
            }  
            allContentfulKeyFeatures(sort: {fields: contentfulid, order: ASC}) {
                nodes {
                  id
                  title
                  description
                  image {
                    file {
                      url
                    }
                    title
                  }
                }
            }
          }
        `
    )

    return (
        <Container id="features" className="anchor">
            <div className="customWrapper">
                <div className="featureWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <div className="featureBlock">
                        {data.allContentfulKeyFeatures.nodes.map(node => {
                            return (
                                <div className="featureItem" key={node.id}>
                                    <div className="featureImage" >
                                        <img
                                            src={node.image.file.url}
                                            alt={node.image.title}
                                        >
                                        </img>
                                    </div>
                                    <div className="featureTitle" >
                                        {node.title}
                                    </div>
                                    <div className="featureDescription">
                                        {node.description}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default KeyFeatures