import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Modal from 'react-modal';

import { Icon } from 'react-icons-kit';
import { cross } from 'react-icons-kit/icomoon/cross'

import Container from "../components/container"
import Video from "../components/video"

import "./aboutvideo.css"

const AboutVideo = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "aboutvideo"}) {
                title
                subtitle
            }   
            contentfulVideos(contentfulid: {eq: 1}) {
                title
                url
                previewImage {
                  file {
                    url
                  }
                }
            }
          }
        `
    )

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Container id="aboutvideo" className="anchor">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal"
                overlayClassName="overlay"
                ariaHideApp={false}
            >
                <Icon icon={cross} className="modalCloseBtn" onClick={closeModal} size="25"/>
                <Video
                    videoSrcURL={`https://www.youtube.com/embed/${data.contentfulVideos.url}`}
                    videoTitle={data.contentfulVideos.title}
                />
            </Modal>
            <div className="customWrapper">
                <div className="aboutVideoWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <a href="#" onClick={openModal}>
                        <div className="aboutVideoBlock">
                            <img
                                src={data.contentfulVideos.previewImage.file.url}
                                alt={data.contentfulVideos.title}
                                width="100%"
                                height="auto"
                                className="previewImage"
                            >
                            </img>
                            <StaticImage
                                src="../images/youtube-play.svg"
                                alt="Youtube Play Button"
                                className="playbutton"
                            />
                        </div>
                    </a>
                </div>
            </div>
        </Container>
    )
}

export default AboutVideo