import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "../components/container"

import crown from '../images/crown.svg';

import "./featurecomparison.css"

const FeatureComparison = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "comparison"}) {
                subtitle
                title
            }
            allContentfulComparison(sort: {fields: contentfulid, order: ASC}) {
                nodes {
                  id  
                  type
                  description
                  suggested
                  list {
                    raw
                  }
                }
            }
          }
        `
    )

    const options = {
        renderNode: {
            [BLOCKS.LIST_ITEM]: (node, children) => <li><Icon icon={checkmarkCircled} /> {node.content[0].content[0].value}</li>,
        },
    }

    return (
        <Container id="comparison" className="anchor">
            <div className="customWrapper">
                <div className="comparisonWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <div className="comparisonBlock">
                        {data.allContentfulComparison.nodes.map(node => {
                            return (
                                <div className="comparisonItem" key={node.id}>
                                    {node.suggested && (
                                        <div className="comparisonTag" >
                                            <img src={crown} alt="Crown" /> Suggested
                                        </div>
                                    )}
                                    <div className="comparisonTitle" >
                                        <h3>{node.type}</h3>
                                        <p>{node.description}</p>
                                    </div>
                                    <div className="comparisonList" >
                                        {renderRichText(node.list, options)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default FeatureComparison