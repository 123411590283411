import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "../components/container"
import Glide from '@glidejs/glide'

import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
import "./appslider.css"

//import "../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css"

const AppSlider = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "appslider"}) {
                subtitle
                bodyText {
                    raw
                }
            }   
            allContentfulAppSlider(sort: {fields: contentfulid, order: ASC}) {
                nodes {
                  id
                  title
                  description
                  imageNumber {
                    title
                    file {
                      url
                    }
                  }
                  appScreenshot {
                    title
                    file {
                      url
                    }
                  }
                }
            }
          }
        `
    )

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        },
    }

    useEffect(() => {
        new Glide('.glide', {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            autoplay: 5000,
            animationDuration: 700,
        }).mount();
    }, []);

    return (
        <Container id="appslider" className="anchor">
            <div className="customWrapper">
                <div className="sliderWrapper">
                    <div className="carousel">
                        <div className="glide">
                            <div data-glide-el="track" className="glide__track">
                                <ul className="glide__slides">
                                    {data.allContentfulAppSlider.nodes.map(node => {
                                        return (
                                            <li className="glide__slide" key={node.id}>
                                                <img
                                                    src={node.appScreenshot.file.url}
                                                    alt={node.appScreenshot.title}
                                                >
                                                </img>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="glide__bullets" data-glide-el="controls[nav]">
                                <button className="glide__bullet" data-glide-dir="=0" aria-label="0"></button>
                                <button className="glide__bullet" data-glide-dir="=1" aria-label="1"></button>
                                <button className="glide__bullet" data-glide-dir="=2" aria-label="2"></button>
                            </div>
                        </div>
                    </div>
                    <div className="slideTextWrapper">
                        <h1>{data.contentfulSection.subtitle}</h1>
                        {renderRichText(data.contentfulSection.bodyText, options)}
                        <div className="sliderBlock">
                            {data.allContentfulAppSlider.nodes.map(node => {
                                return (
                                    <div className="sliderItems" key={node.id}>
                                        <div className="sliderImage">
                                            <img
                                                src={node.imageNumber.file.url}
                                                alt={node.imageNumber.title}
                                            >
                                            </img>
                                        </div>
                                        <div className="sliderText">
                                            <h2>
                                                {node.title}
                                            </h2>
                                            <p>
                                                {node.description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default AppSlider